import React from 'react';
import Layout from "../../components/layout";

const Moshi = () => {
  return (
    <Layout>
      <h1>Moshi</h1>
      <h2>Instructors</h2>
      <p>Senpai Hassan Mwembe</p>

      <p>Moshi Dojo is stituated in the Kilamanjaro region of Moshi, a northern area of Tanzania. With approximately 20+
        students the dojo is under the guidance of Chief Instructor Sensei Kheri, who has given instruction for Senpai
        Bilal, Mwinyivua and Wahid to visit the branch regularly so as to keep track of training progress.</p>

    </Layout>
  );
}

export default Moshi;
